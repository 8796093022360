<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            to="/configuration/template-models/generator"
          >
            Create a Campaign Model
            <feather-icon icon="FilePlusIcon" size="12" />
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(image_preview)="data">
            <b-avatar :src="path + data.value" />
          </template>
          <!--
          <template #cell(url)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              target="_blank"
              :href="data.value"
            >
              Preview
            </b-button>
          </template>
          -->

          <!-- <template #cell(status)="data"> -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.status == true" pill variant="success">
              running
            </b-badge>
            <b-badge v-if="data.item.status == false" pill variant="warning">
              paused
            </b-badge>
            <!--
            <b-badge pill :variant="__order_status.variant">
              {{ __order_status.status }}
            </b-badge> -->
            <!-- <b-badge pill :variant="data.value.variant">
              {{ data.value.status }}
            </b-badge>
            -->
          </template>
          <template #cell(actions)="data">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-info"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Edit"
                @click="editTemplateModel(data.item.id)"
              >
                <feather-icon icon="EditIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Delete"
                @click="confirmDeleteTemplateModel(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" size="20" />
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
      <b-modal
        id="modal-danger"
        ok-variant="danger"
        ok-title="Confirm"
        modal-class="modal-danger"
        centered
        title="Deleting Campaign model !"
        @ok="deleteTemplateModel"
      >
        <b-card-text>
          Are you sure you want to delete this Campaign model?
        </b-card-text>
      </b-modal>
      <b-col cols="6">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-25"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BCardText,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import store from "@/store";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";

export default {
  components: {
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      path: this.$ImagesURL + "template/",
      perPage: 10,
      _id: null,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      __order_status: { status: "Pending", variant: "primary" },
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "title",
          label: "Title",
        },
        {
          key: "image_preview",
          label: "Image",
        },
        "Actions",
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.showAllTemplateModels();
  },
  methods: {
    confirmDeleteTemplateModel(id) {
      this._id = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    deleteTemplateModel(id) {
      axios
        .post("/delete-template-model", {
          id: this._id,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "TrashIcon", "Done", data.message);
            this.showAllTemplateModels();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch(console.error);
    },
    editTemplateModel: (id) => {
      store
        .dispatch("appsManager/setTemplateModelId", id)
        .then(_ => {
          router.push({ name: "configuration-template-models-edit" });
        })
        .catch(console.error);
    },
    // editTemplateModel(id) {
    //   this.$store
    //     .dispatch("appsManager/SETTEMPLATEMODELID", id)
    //     .then(() => {
    //       this.$router.push({ name: "configuration-template-models-edit" });
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async showAllTemplateModels() {
      this.$store
        .dispatch("appsManager/getAllTemplateModels")
        .then(({ data }) => {
          this.items = data["template-models"];
        })
        .catch(console.error);
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>